<template>
  <div class="app-content content ecommerce-application" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="content-body">
        <section class="invoice-preview-wrapper">
          <div v-if="clientReturn" class="row invoice-preview">
            <div class="col-12 col-xl-9 col-md-8">
              <div class="card invoice-preview-card">
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table b-table">
                      <thead>
                      <tr>
                        <th>Товар</th>
                        <th>Фото</th>
                        <th>Размер</th>
                        <th>Состояние</th>
                        <th>К-во</th>
                        <th>Вес (кг)</th>
                        <th>Цена</th>
                        <th>Количество в заказе</th>
                        <th>Цена в заказе</th>
                      </tr>
                      </thead>
                      <tbody>
                      <template v-for="(item) in clientReturn.index_items">
                        <tr v-for="object in item" :key="object.item.id" class="grouped-row">
                          <td>
                            <router-link
                                class="text-body"
                                :to="{name: 'product-details', params: { id: object.item.order_item.product_specification.product.id}}"
                            >
                              {{ object.item.order_item.product_specification.product.name }}
                              {{ object.item.order_item.product_specification.product.brand_name }}
                            </router-link>
                            <br/>
                            <small>Артикул: {{ object.item.order_item.product_specification.product.sku }}</small> <br/>
                          </td>
                          <td class="p-0">
                            <img
                                :src="`/img/product/${object.item.order_item.product_specification.product.small_main_image}`"
                                class="product-img" alt=""
                            >
                          </td>
                          <td class="text-uppercase">{{ object.item.order_item.product_specification.size_name }}</td>
                          <td>{{ object.item.order_item.product_specification.condition_name }}</td>
                          <td>{{ object.qty }}</td>
                          <td>{{ object.item.order_item.product_specification.weight / 1000 }}</td>
                          <td v-if="$can('read', 'payments')">
                            {{ object.item.cost }} <span class="currency">{{ object.item.currency.name }}</span>
                          </td>
                          <td>{{ object.item.order_item.qty }}</td>
                          <td v-if="$can('read', 'payments')">
                            {{ object.item.order_item.cost }} <span class="currency">{{ object.item.order_item.currency.name }}</span>
                          </td>
                        </tr>
                      </template>
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr class="invoice-spacing">
                <div class="card-body invoice-padding pb-0">
                  <div class="row">
                    <div class="col-12 col-md-6 mt-md-0 mt-3 order-2 order-md-1">
                    </div>
                    <div class="col-12 col-md-6 order-1 order-md-2 mt-md-6 d-flex justify-content-end mb-2">
                      <div class="invoice-total-wrapper">
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">Кол-во:</p>
                          <p class="invoice-total-amount">{{ clientReturn.total_qty }}</p>
                        </div>
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">Вес (кг):</p>
                          <p class="invoice-total-amount">{{ clientReturn.total_weight / 1000 }}</p>
                        </div>
                        <hr class="my-50" v-if="$can('read', 'payments')">
                        <div class="invoice-total-item" v-if="$can('read', 'payments')">
                          <p class="invoice-total-title">Сумма:</p>
                          <p class="invoice-total-amount">
                            {{ clientReturn.total_amount }}
                            <span class="currency">
                              {{ clientReturn.items.length > 0 && clientReturn.items[0].currency.name }}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4 col-xl-3 invoice-actions">
              <div class="card">
                <div class="card-body">
                  <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      class="mb-75"
                      block
                      @click="printReturn"
                  >
                    Распечатать
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

export default {
  name: 'ReturnDetails',
  directives: { Ripple },
  components: { BButton },
  data() {
    return {
      clientReturn: {
        items: [],
      },
    };
  },
  async mounted() {
    await this.getReturnInfo();
  },
  methods: {
    async getReturnInfo() {
      this.clientReturn = (await this.$api.returns.get(this.$route.params.id)).data;
    },
    printReturn() {
      window.print();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}

span.currency {
  font-size: 10px;
}

.product-img {
  max-height: 80px;
}

.grouped-row {
  background-color: #f9f9f9;
}
.invoice-date-title {
  width: auto !important;
}
</style>
